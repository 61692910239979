
import { of, EMPTY } from 'rxjs';
import { tap, mergeMap, ignoreElements } from 'rxjs/operators';

import { combineEpics, ofType } from 'redux-observable';

import { ActionGeneratorBuilder, deltaReducer } from '../../actions';

import { actions as deviceStatesActions } from '../../api/deviceStates';
import { actions as sessionActions } from '../../api/session';
import { determineDeviceStatus } from '../../../lib/device';

import StatusUpdateDaemon from './daemon';

const defaultState = {
	map: null // uri => { status: string }
};

const actions = new ActionGeneratorBuilder('deviceStatus')
	.type('changed', { map: true })
	.type('update', { uri: true })
	.build()
;

// ---------------------------------------------------------
const reducer = deltaReducer((state, action) => {
	switch (action.type) {
		case actions.changed.type: return {
			map: { ...state.map, ...action.map }
		};
	}
	return null;
}, defaultState);

// ---------------------------------------------------------
const watchStatesEpic = (action$, state$) => {
	return action$.pipe(
		ofType(deviceStatesActions.find.success.type)
		, mergeMap(action => {
			const statuses = state$.value.deviceStatuses.map || {};
			let change = null;
			action.states.forEach(({uri, state}) => {
				const status = determineDeviceStatus(state.message);
				if (statuses[uri]?.status != status) {
					if (change == null) change = {};
					change[uri] = { status };
					StatusUpdateDaemon.getInstance().onChange(uri, status);
				}
			});
			if (change == null) return EMPTY;
			return of(actions.changed({ map: change }));
		})
	);
}

const updateEpic = (action$, state$) => {
	return action$.pipe(
		ofType(actions.update.type)
		, mergeMap(action => {
			const deviceState = state$.value.deviceStates.map?.[action.uri];
			const status = determineDeviceStatus(deviceState?.message);
			StatusUpdateDaemon.getInstance().onChange(action.uri, status);
			if (status == state$.value.deviceStatuses.map?.[action.uri]?.status) return EMPTY;
			return of(actions.changed({ map: { [action.uri]: { status }}}));
		})
	);
}

const watchSessionEpic = (action$) => {
	return action$.pipe(
		ofType(sessionActions.events.paused.type, sessionActions.events.closed.type)
		, tap(() => StatusUpdateDaemon.getInstance().reset())
		, ignoreElements()
	)
}

const epic = combineEpics(watchStatesEpic, updateEpic, watchSessionEpic);

// ---------------------------------------------------------

export { actions, reducer, epic };
