import { switchMap, map, tap, ignoreElements } from 'rxjs/operators';

import { ofType, combineEpics,  } from 'redux-observable';

import { rx, ods, api } from 'core/api';

import { deltaReducer, ActionGeneratorBuilder, errorMap } from 'core/redux/actions';

import { actions as announcerActions } from 'core/redux/api/announcer';
import { actions as sessionActions } from 'core/redux/api/session';

import Processor from './Processor';

const actions = new ActionGeneratorBuilder('deviceStates')
	.subtype('find', find => find.request('filter').success('states').fail())
	.build()
;

const defaultState = {
	map: null, // {uri => StateDetails}
	pending: false,
	error: null
};

const reducer = deltaReducer((state, action) => {
	switch (action.type) {
		case actions.find.request.type: return {
			pending: true
			, error: null
		};
		case actions.find.success.type: return {
			pending: false
			, map: action.states.length == 0 ? state.map : { ...state.map, ...Object.fromEntries(action.states.map(({uri, state}) => [uri, state])) }
		};
		case actions.find.fail.type: return {
			pending: false
			, error: action.errorMessage
		};
	}
	return null;
}, defaultState);


let processor = null;
function connect(store) {
	processor = new Processor(store);
}

const findEpic = (action$, state$) => {
	return action$.pipe(
		ofType(actions.find.request.type),
		switchMap(action => {
			processor.lock();
			return rx(api.devices.states.find, action.filter).pipe(
				tap(() => processor.unlock())
				, map(operation => actions.find.success({states: operation.response()}))
				, errorMap(actions.find.fail)
			)
		})
	)
};

const watchSessionStartEpic = (action$) => {
	return action$.pipe(
		ofType(sessionActions.events.resumed.type, sessionActions.events.started.type)
		, map(action => actions.find.request({ filter: new ods.devices.FleetStateFilter() }))
	)
};

const watchAnnouncedEpic = (action$) => {
	return action$.pipe(
		ofType(announcerActions.announced.type)
		, tap(action => processor.announce(action.announcements))
		, ignoreElements()
	)
};

const watchSessionCloseEpic = (action$) => {
	return action$.pipe(
		ofType(sessionActions.events.paused.type, sessionActions.events.closed.type)
		, tap(() => processor.stop())
		, ignoreElements()
	)
};

const epic = combineEpics(findEpic, watchAnnouncedEpic, watchSessionStartEpic, watchSessionCloseEpic);

export { actions, reducer, connect, epic };
