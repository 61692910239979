import React from 'react';
import { connect } from 'react-redux';
import moment from "moment";

import Loader from '../../../general/Loader';
import Address from '../../../general/location/Address';
import DeviceStatusIcon from '../../../share/icons/DeviceStatusIcon';
import GpsFixIcon from '../../../share/icons/GpsFixIcon';
import IgnitionIcon from '../../../share/icons/IgnitionIcon';
import PinIcon from '../../../../img/icons/pin-full.png';
import DeviceAssetTypeIcon from '../../../share/devices/DeviceAssetTypeIcon';
import { Link } from 'react-router-dom';
import { FiSliders } from 'react-icons/fi';
import { DeviceDetailsProxy } from '../../../../api/device'; // eslint-disable-line
import { DeviceExpirationIcon } from 'core/react/share/icons/device-expiration-icon';

/**
 * @param {Object} props
 * @param {DeviceDetailsProxy} props.device
 * @param {function} props.togglePin
 * @param {boolean} [props.selected]
 * @param {string} [props.reduxKey]
 * @param {boolean} [props.pinned]
 */

function DeviceListItemContent(props) {
	const { device, togglePin, pinned, statesLoading, deviceState } = props;
	const displayDetails = props.reduxKey != 'timeMachine' || props.selected;

	const location = () => {
		if (deviceState != null) {
			return (<Address message={deviceState.message} showIcon />);
		} else if (statesLoading) {
			return (<Loader />);
		}
	}

	const icons = () => {
		if (deviceState == null) return [];
		const list = [];
		if (device.expiresAt != null) {
			const isExpired = moment(device.expiresAt).isSameOrBefore(moment());
			const isExpiresSoon = moment(device.expiresAt).isSameOrBefore(moment().add(1, 'M'));
			if (isExpired || isExpiresSoon) list.push(<DeviceExpirationIcon key="expiration" isExpired={isExpired} expiresAt={device.expiresAt} />);
		}
		list.push(<DeviceStatusIcon key="status" uri={device.uri} reduxKey={props.reduxKey} />);
		list.push(<IgnitionIcon key="ignition" message={deviceState.message} />);
		list.push(<GpsFixIcon key="gnss" message={deviceState.message} />);
		return list;
	}

	return (
		<div className="device-list-item-content">
			<div className="wrap">
				{pinned &&
					<div className="pin-block" onClick={togglePin}>
						<img className="pin-icon" alt="" src={PinIcon} />
					</div>
				}
				<div className="icon">
					<DeviceAssetTypeIcon categoryIds={device.categoryIds} />
				</div>
				<div className="content">
					<div>{device.denomination()}</div>
					{displayDetails && <>
						<div>{location()}</div>
						<div className="icons">{icons()}</div>
					</>}
				</div>
			</div>
			{props.selected &&
				<Link className="action" to={'/device/' + props.device.uri + '/control-panel'}><FiSliders /></Link>
			}
		</div>
	);
}

export default connect(
	(state, props) => {
		const deviceStates = props.reduxKey == 'timeMachine' ? state.timeMachine.state : state.deviceStates;
		if (deviceStates.map == null) return { statesLoading: deviceStates.pending };
		return { deviceState: deviceStates.map[props.device.uri] };
	}
)(DeviceListItemContent);
