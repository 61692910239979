import React, { useEffect, useState, useContext } from 'react';
import { resolve } from '../../../misc/location/resolve';
import locationIcon from '../../../img/icons/location.png';
import Loader from '../Loader';
import { ResizeContext } from '../../../misc/ResizeContext';
import { className } from '../../../lib/className';
import './address.scss';

/**
 * @param {Object} props
 * @param {cx.ods.devices.MessageDetails|Object} [prpos.message]
 * @param {boolean} [props.full]
 * @param {boolean} [props.showIcon]
 */

function Address(props) {
	const [addressString, setAddressString] = useState(null);
	const [resolving, setResolving] = useState(false);
	const resizeContext = useContext(ResizeContext);

	const getLocation = props => {
		const message = props.message;
		if (message == null) return props.location;
		if (message.longitude == null && message.latitude == null) return null;
		return { longitude: message.longitude, latitude: message.latitude };
	}
	const location = getLocation(props);

	const getAddressLocation = location => {
		if (location == null) return null;
		const decimals = 4;
		return { longitude: location.longitude.toFixed(decimals), latitude: location.latitude.toFixed(decimals) };
	}
	const addressLocation = getAddressLocation(location);

	useEffect(() => {
		setAddressString(null);
		if (addressLocation == null) return;
		const entry = resolve(addressLocation.longitude, addressLocation.latitude, resolveComplete, resolveInProgress, addressLocation);
		return () => entry.removeObservers(addressLocation);
	}, [addressLocation?.longitude, addressLocation?.latitude]);

	const resolveComplete = entry => {
		setResolving(false);
		setAddressString(entry.getAddress()?.format());
	};

	const resolveInProgress = () => {
		setResolving(true);
	};

	useEffect(() => {
		if (addressString && resizeContext?.resize) resizeContext.resize();
	}, [addressString]);

	const locationString = location ? "(" + location.longitude.toFixed(6) + "; " + location.latitude.toFixed(6) + ")" : "n/a";

	return (
		<span className={className('location', { 'resolved': !!addressString })}>
			{props.showIcon &&
				<span className="location-icon">
					<img src={locationIcon} alt="" />
				</span>
			}
			{addressString &&
				<span>{addressString}</span>
			}
			{(!addressString || props.full) &&
				<span className="coordinates">{locationString}</span>
			}
			{resolving &&
				<Loader className="loader" size={Loader.Size.XS} />
			}
		</span>
	);
}

export default Address;
