import React from 'react';
import ReactDOM from 'react-dom';
import OlOverlay from 'ol/Overlay';
import { fromEPSG4326 } from 'ol/proj/epsg3857';
import { className, fromProps } from '../../../lib/className';
import { capitalize } from '../../../misc/misc';

class OwMapMarker {

	/**
	 * @param {Object} props
	 * @param {DOMElement} props.box
	 * @param {Array.<number>} props.coordinates
	 * @param {string} props.id
	 */

	constructor(box, coordinates, id) {
		this.overlay = new OlOverlay({
			element: box,
			position: fromEPSG4326(coordinates),
			positioning: 'top-left',
			id: id,
			stopEvent: false
		});
	}

	onClick(callback) {

	}

	getOverlay() {
		return this.overlay;
	}

	moveTo(coordinates) {
		const position = fromEPSG4326(coordinates);
		const overlayPosition = this.overlay.getPosition();
		if (overlayPosition[0] == position[0] && overlayPosition[1] == position[1]) return;
		this.overlay.setPosition(position);
		this.updatePresence();
	}

	hide(hidden = true) {
		if (hidden == Boolean(this.hidden)) return;
		this.hidden = hidden;
		this.updatePresence();
	}

	addToMap(map) {
		this.map = map;
		this.updatePresence();
		// this.mapListener = () => this.updatePresence();
		// this.map.getOlMap().on('moveend', this.mapListener);
	}

	updatePresence() {
		const present = !this.hidden; // && containsCoordinate(this.map.getBufferedExtent(), this.overlay.getPosition());
		if (present == Boolean(this.present)) return;
		const olMap = this.map.getOlMap();
		if (present) olMap.addOverlay(this.overlay);
		else olMap.removeOverlay(this.overlay);
		this.present = present;
	}

	destroy() {
		if (this.map) {
			const olMap = this.map.getOlMap();
			// olMap.un('moveend', this.mapListener); this.mapListener = null;
			olMap.removeOverlay(this.overlay);
		}
		this.overlay = null;
	}
}

/**
 * @param {Object} props
 * @param {OwMap} props.map
 * @param {Array.<number>} props.coordinates [decimal, decimal] -- (lon, lat)
 * @param {string} props.id
 * @param {string} [props.className]
 * @param {function} [props.onClick]
 * @param {Object} [props.params]
 * @param {Object} [props.properties]
 * @param {Object} [props.bodyStyle]
 * @param {string} [props.title]
 * @param {string} [props.label]
 * @param {React.Component} [props.customElement]
 */
class MapMarker extends React.Component {

	shouldComponentUpdate(nextProps, nextState) {
		const props = this.props;
		if (props.coordinates[0] != nextProps.coordinates[0] || props.coordinates[1] != nextProps.coordinates[1]) return true;
		if (props.className != nextProps.className) return true;
		if (props.bodyStyle != nextProps.bodyStyle) return true;
		if (Boolean(props.params) != Boolean(nextProps.params)) return true;
		if (props.params) {
			const nextPropKeys = Object.keys(nextProps.params);
			if (Object.keys(props.params).length != nextPropKeys.length) return true;
			if (nextPropKeys.some(key => nextProps.params[key] != props.params[key])) return true;
		}
		if (props.label != nextProps.label) return true;
		if (props.title != nextProps.title) return true;
		if (props.id != nextProps.id) return true;
		return false;
	}

	render() {
		if (!this.marker) this.attach();
		else this.update();
		const props = this.props;
		const overlay = this.marker.getOverlay();
		if (props.params) {
			overlay.autoPan = props.params.autoPan;
			overlay.autoPanMargin = props.params.autoPanMargin;
		}
		if (props.properties) overlay.setProperties(props.properties);
		this.marker.moveTo(props.coordinates);
		if (props.bodyStyle) Object.keys(props.bodyStyle).forEach((name) => {
			this.body.style[name] = props.bodyStyle[name];
		});
		if (this.marker && props.customElement) return ReactDOM.createPortal(
			<div className="custom-element-map-marker">{props.customElement}</div>,
			this.marker.getOverlay().getElement().parentNode,
		);
		return null;
	}

	attach() {
		const props = this.props;
		this.box = document.createElement('div');
		this.box.addEventListener('click', () => {
			if (this.props.onClick) this.props.onClick();
		});
		this.box.className = 'marker';
		this.body = this.box.appendChild(document.createElement('div'));
		this.body.className = 'body';
		this.marker = new OwMapMarker(this.box, props.coordinates, props.id);
		this.marker.addToMap(props.map);
		this.marker.getOverlay().set('displayLabel', this.updateLabel.bind(this));
		this.update();
	}

	update() {
		this.box.className = className('marker', fromProps(this.props));
		this.box.title = capitalize(this.props.title);
		this.updateLabel();
	}

	updateLabel() {
		const text = this.props.map.isDisplayedMarkerLabels() && this.props.label;
		if (!text) {
			if (this.label) {
				this.label.parentNode.removeChild(this.label);
				this.label = null;
			}
			return;
		}
		if (this.label) {
			this.label.firstChild.innerText = text;
			return;
		}
		const label = document.createElement('div');
		label.classList.add('marker-label');
		label.appendChild(document.createElement('div')).innerText = text;
		this.box.appendChild(label);
		this.label = label;
	}

	setZIndex = (zIndex) => {
		if (this.marker) this.marker.getOverlay().getElement().parentNode.style.zIndex = zIndex;
		else console.log('setZIndex, no marker', this);
	};

	getDomBox() {
		return this.marker.getOverlay().getElement();
	}

	componentWillUnmount() {
		if (this.marker) {
			this.marker.destroy();
			this.marker = null;
		}
	}
}

export default MapMarker;
