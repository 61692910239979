import { LogLevel, rootLogger } from 'core/lib/log';
import { singleton } from '../object';
import Observable from '../Observable';

const logger = rootLogger.logger('resolver').at(LogLevel.Warning);

export class ResolverEntry extends Observable {

	constructor(longitude, latitude) {
		super({ cancelled: 'cancelled', completed: 'completed' });
		this.longitude = longitude;
		this.latitude = latitude;
	}

	ready() { return this.address !== undefined; }

	observe(handleReady, handleBusy, processor) {
		if (this.ready()) {
			if (handleReady) handleReady.call(processor, this);
		} else {
			if (handleBusy) handleBusy.call(processor, this);
			if (handleReady) this.addObserver(this.events.completed, handleReady, processor);
		}
		return this;
	}

	resolve(address) {
		this.address = address || null;
		this.notifyObservers(this.events.completed, this);
	}

	cancel() {
		this.address = null;
		this.wasCancelled = true;
		this.notifyObservers(this.events.cancelled, this);
		this.notifyObservers(this.events.completed, this);
	}

	cancelled() {
		return Boolean(this.wasCancelled);
	}

	getAddress() { return this.address; }

	toString() {
		return `(${this.longitude}, ${this.latitude}), ${this.address ? this.address : "no address"}`;
	}
}


export class ResolverFialedError extends Error {

	constructor(message) {
		super(`Resolver failed: ${message}`);
	}
};


export const ResolverThrottledError = singleton(class extends Error {

	constructor() {
		super('Resolver throttled');
	}
});


export class AbstractResolver {

	name() {
		return this.constructor.name;
	}

	submit(entry) {
		return Promise.resolve(null);
	}

	commit() {
	}
}


export class BatchResolver extends AbstractResolver {

	constructor(batchSize = 10) {
		super();
		this.batchSize = batchSize;
		this.batch = [];
	}

	submit(entry) {
		const resolving = new Promise((resolve, reject) => this.batch.push({entry, resolve, reject}));
		if (this.batchSize <= this.batch.length) this.flush();
		return resolving;
	}

	commit() {
		if (0 < this.batch.length) this.flush();
	}

	flush() {
		this.submitBatch(this.batch);
		this.batch = [];
	}

	submitBatch(batch) {
		batch.forEach(job => job.resolve(null));
	}
}

export class RateLimitingResolveService {

	constructor(queue, resolver, maxRPS = 10, queueFilter = undefined) {
		this.queue = queue;
		this.resolver = resolver;
		this.queueFilter = queueFilter;
		if (1 <= maxRPS) {
			this.maxLimit = maxRPS;
			this.minLimitPeriod = 1000;
		} else {
			this.maxLimit = 1;
			this.minLimitPeriod = 1000 / maxRPS;
		}
		this.maxLimitPeriod = this.minLimitPeriod * 5;
		this.failureDelay = this.maxLimitPeriod * 2;
		this.initialize();
		this.queue.attachService(this);
		this.queue.addObserver(this.queue.events.grown, this.requestRun, this);
		this.queue.addObserver(this.queue.events.resumed, this.requestRun, this);
	}

	initialize() {
		this.limit = this.maxLimit;
		this.limitPeriod = this.minLimitPeriod;
		this.starts = [];
		this.limitedTill = Date.now();
		this.resolved = this.throttled = 0;
	}

	requestRun() {
		if (this.runTimer == null && this.delayTimer == null) this.runTimer = setTimeout(() => {
			this.runTimer = null;
			this.run();
		});
	}

	run() {
		const commitStart = this.admitStart(), entry = commitStart != null ? this.queue.poll(this, this.queueFilter) : null;
		if (/*commitStart == null ||*/ entry == null) {
			this.resolver.commit();
			return;
		}
		const ticket = commitStart();
		logger.debug('Submitting %s to %s, rate %s/%s, limit %s/%s, queued %s'
			, ticket.start, this.resolver.name(), ticket.quantity, ticket.period, this.limit, this.limitPeriod, this.queue.length()
		);
		this.resolver.submit(entry).then(address => {
			++this.resolved;
			logger.debug('Resolved %s (%s)', ticket.start, this.resolved);
			this.regain(ticket);
			this.queue.processed(this, entry, address);
		}).catch(error => {
			if (error instanceof ResolverThrottledError) {
				++this.throttled;
				logger.debug('Throttled %s (%s)', ticket.start, this.throttled);
				this.throttle(ticket);
				this.queue.rejected(this, entry);
			} else if (error instanceof ResolverFialedError) {
				logger.error('Resolver failed %s, error: %o', ticket.start, error);
				this.failureBackOff();
				this.queue.rejected(this, entry);
			} else {
				logger.warning('Resolve request failed %s: %s, %s', ticket.start, String(error), String(entry));
				this.queue.processed(this, entry);
			}
		}).finally(() => this.requestRun());
		this.requestRun();
	}

	admitStart() {
		if (this.delayTimer != null) return null;
		const now = Date.now(), periodStart = now - this.limitPeriod;
		while (0 < this.starts.length && this.starts[0] <= periodStart) this.starts.shift();
		if (this.starts.length < this.limit) return () => {
			this.starts.push(now);
			return {start: now, quantity: this.starts.length, period: this.limitPeriod};
		};
		const delay = this.starts[this.starts.length - this.limit] + this.limitPeriod - now + 1;
		logger.debug('Rate limited at %s/%s, pausing for %s', this.limit, this.limitPeriod, delay);
		this.delayTimer = setTimeout(() => {
			this.delayTimer = null;
			this.run();
		}, delay);
		return null;
	}

	throttle(ticket) {
		if (ticket.start <= this.limitedTill) return;
		if (this.limit <= 1 && this.maxLimitPeriod <= this.limitPeriod) {
			logger.debug('Rate is at the minimum of %s/%s', this.limit, this.limitPeriod);
			return;
		}
		if (ticket.period * this.limit < ticket.quantity * this.limitPeriod) {
			logger.debug('Rate %s/%s is already lower than that of ticket', this.limit, this.limitPeriod);
			return;
		}
		if (1 < this.limit) {
			this.limit = Math.max(ticket.quantity - 1, 1);
			logger.debug('Limit decreased to %s', this.limit);
		} else /*if (this.limitPeriod < this.maxLimitPeriod)*/ {
			this.limitPeriod = Math.min(ticket.period * 2, this.maxLimitPeriod);
			logger.debug('Period increased to %s', this.limitPeriod);
		}
		this.limitedTill = ticket.start;
	}

	regain(ticket) {
		if (ticket.start <= this.limitedTill) return;
		if (this.maxLimit <= this.limit && this.limitPeriod <= this.minLimitPeriod) return;
		if (ticket.quantity * this.limitPeriod < ticket.period * this.limit) {
			logger.debug('Rate %s/%s is already higher than that of ticket', this.limit, this.limitPeriod);
			return;
		}
		if (this.minLimitPeriod < this.limitPeriod) {
			this.limitPeriod = Math.min(this.limitPeriod / 2, this.minLimitPeriod);
			logger.debug('Period decreased to %s', this.limitPeriod);
		} else /*if (this.limit < this.maxLimit)*/ {
			++this.limit;
			logger.debug('Limit increased to %s', this.limit);
		}
		this.limitedTill = ticket.start;
	}

	failureBackOff() {
		if (this.delayTimer != null) clearTimeout(this.delayTimer);
		if (!this.faulireDelaying) {
			logger.warning('Backing off for %s', this.failureDelay);
			this.faulireDelaying = true;
		}
		this.delayTimer = setTimeout(() => {
			this.delayTimer = null;
			this.faulireDelaying = false;
			this.run();
		}, this.failureDelay);
	}
}


export class RegainingQuotaResolveService {

	constructor(queue, resolver, maxQuota = 10, regainingRate = 0.5, queueFilter = undefined, failureDelay = 5000) {
		this.queue = queue;
		this.resolver = resolver;
		this.queueFilter = queueFilter;
		this.maxQuota = maxQuota;
		this.regainingDelay = 1000 / regainingRate;
		this.failureDelay = failureDelay;
		this.initialize();
		this.queue.attachService(this);
		this.queue.addObserver(this.queue.events.grown, this.start, this);
		this.queue.addObserver(this.queue.events.resumed, this.start, this);
	}

	initialize() {
		this.runningLimit = this.maxQuota;
		this.running = 0;
		this.resolved = this.throttled = 0;
	}

	start() {
		if (this.running == 0) this.requestRun();
	}

	requestRun() {
		if (this.runTimer == null && this.delayTimer == null) this.runTimer = setTimeout(() => {
			this.runTimer = null;
			this.run();
		});
	}

	run() {
		const quota = this.quota(), entry = this.running < quota ? this.queue.poll(this, this.queueFilter) : null;
		if (/*this.running < limit ||*/ entry == null) {
			this.resolver.commit();
			return;
		}
		++this.running;
		logger.debug('Submitting to %s: running %s, quota %s, queued %s', this.resolver.name(), this.running, quota, this.queue.length());
		this.resolver.submit(entry).then(address => {
			--this.running;
			++this.resolved;
			logger.debug('Resolved (%s)', this.resolved);
			this.queue.processed(this, entry, address);
		}).catch(error => {
			--this.running;
			if (error instanceof ResolverThrottledError) {
				this.throttle();
				this.queue.rejected(this, entry);
			} else if (error instanceof ResolverFialedError) {
				logger.error('Resolver failed: %s', String(error));
				this.failureBackOff();
				this.queue.rejected(this, entry);
			} else {
				logger.warning('Resolve request failed: %s, %s', String(error), String(entry));
				this.queue.processed(this, entry);
			}
		}).finally(() => this.requestRun());
		if (this.running < quota) this.requestRun();
	}

	throttle() {
		if (this.delayTimer != null) return;
		++this.throttled;
		logger.debug('Throttled (%s), resetting quota, pausing to regain', this.throttled);
		this.runningLimit = 1;
		this.delayTimer = setTimeout(() => {
			this.delayTimer = null;
			this.unthrottlingStart = Date.now();
			this.run();
		}, this.regainingDelay);
	}

	failureBackOff() {
		if (this.delayTimer != null) clearTimeout(this.delayTimer);
		if (!this.faulireDelaying) {
			logger.warning('Backing off for %s', this.failureDelay);
			this.faulireDelaying = true;
		}
		this.delayTimer = setTimeout(() => {
			this.delayTimer = null;
			this.faulireDelaying = null;
			this.run();
		}, this.failureDelay);
	}

	quota() {
		if (this.delayTimer != null) return 0;
		if (this.runningLimit < this.maxQuota) {
			const unshrottled = Math.floor((Date.now() - this.unthrottlingStart) / this.unshrottleDelay);
			if (0 < unshrottled) {
				this.runningLimit = Math.min(1 + unshrottled, this.maxQuota);
				logger.debug('Regained to %s', this.runningLimit);
			}
		}
		return this.runningLimit;
	}
}


export const ResolverQueue = singleton(class extends Observable {

	constructor(maxSize = 5000) {
		super({grown:  'grown', paused: 'paused', resumed: 'resumed', reset: 'reset'});
		this.maxSize = maxSize;
		this.services = new Set();
		this.initialize();
	}

	attachService(service) {
		this.services.add(service);
	}

	initialize() {
		this.queue = [];
		this.paused = false;
		this.overflown = false;
	}

	enqueue(entry) {
		this.dropOverflow();
		this.queue.push(entry);
		this.notifyObservers(this.events.grown);
	}

	dropOverflow() {
		if (this.maxSize <= this.queue.length) {
			if (!this.overflown) {
				logger.error('Resolver queue is overflown, dropping entries');
				this.overflown = true;
			}
			do {
				this.cancelled(this.queue.shift());
			} while (this.maxSize <= this.queue.length);
		} else if (this.overflown) {
			logger.warning('Resolver queue is back within its limit');
			this.overflown = false;
		}
	}

	filters() {
		return {
			last: () => entry => (entry.attempted?.size || 0) + 1 == this.services.size
		};
	}

	poll(service, filter = entry => true) {
		if (this.paused) return null;
		let at = 0;
		while (at < this.queue.length) {
			const entry = this.queue[at];
			if (!entry.hasObservers(entry.events.completed)) {
				this.queue.splice(at, 1);
				this.cancelled(entry);
			} else if (entry.attempted?.has(service)) ++at;
			else if (filter(entry)) {
				this.queue.splice(at, 1);
				return entry;
			} else ++at;
		}
		return null;
	}

	processed(service, entry, address = null) {
		if (address == null && (entry.attempted?.size || 0) + 1 < this.services.size) {
			if (!entry.attempted) entry.attempted = new Set();
			entry.attempted.add(service);
			this.putBack(entry);
			return;
		}
		delete entry.attempted;
		entry.resolve(address);
	}

	rejected(service, entry) {
		this.putBack(entry);
	}

	cancelled(entry) {
		delete entry.attempted;
		entry.cancel();
	}

	putBack(entry) {
		if (!entry.hasObservers()) return;
		this.queue.unshift(entry);
		this.notifyObservers(this.events.grown);
	}

	length() {
		return this.queue.length;
	}

	pause() {
		this.paused = true;
		this.notifyObservers(this.events.paused);
	}

	resume() {
		this.paused = false;
		this.notifyObservers(this.events.resumed);
	}

	reset() {
		this.queue.forEach(entry => entry.cancel());
		this.initialize();
		this.notifyObservers(this.events.reset);
	}
});


export const ResolverCache = singleton(class {

	constructor(maxSize = 500, cleanBy = Math.ceil(maxSize * 0.02)) {
		this.maxSize = maxSize;
		this.cleanBy = cleanBy;
		this.entries = new Map();
		this.requests = this.hits = 0;
		this.statsBy = cleanBy * 5;
		this.statsAfter = this.statsBy;
	}

	put(entry) {
		if (this.maxSize <= this.entries.size) this.clean();
		const key = this.key(entry.longitude, entry.latitude);
		entry.addObserver(entry.events.cancelled, () => {
			const cached = this.entries.get(key);
			if (cached == entry) this.entries.delete(key);
		}, this);
		this.entries.set(key, entry);
		return entry;
	}

	get(longitude, latitude) {
		++this.requests;
		const key = this.key(longitude, latitude);
		const entry = this.entries.get(key);
		if (entry != null) {
			this.entries.delete(key);
			this.entries.set(key, entry);
			++this.hits;
		}
		this.traceStats();
		return entry;
	}

	clean() {
		const cleanSize = this.entries.size - this.cleanBy;
		for (const [key] of this.entries) {
			this.entries.delete(key);
			if (this.entries.size <= cleanSize) return;
		}
	}

	key(longitude, latitude) {
		return Number(longitude).toFixed(5) + "," + Number(latitude).toFixed(5);
	}

	traceStats() {
		if (this.requests < this.statsAfter) return;
		logger.info('Resolver cache: size %s, requests: %s, hits: %s (%s)%', this.entries.size, this.requests, this.hits, Math.round(this.hits * 100 / this.requests));
		this.statsAfter = this.requests + this.statsBy;
	}
});
