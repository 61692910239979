import { cx, ods } from '../../../api';

import { actions } from './index';

class Processor {

	constructor(store) {
		this.store = store;
		this.filter = new ods.devices.FleetStateFilter();
		this.announced = {};
	}

	start() {
		if (this.started()) return;
		this.timer = setInterval(this.tick, 5000);
	}

	started() {
		return Boolean(this.timer);
	}

	stop() {
		if (this.timer) {
			clearInterval(this.timer);
			this.timer = null;
		}
	}

	lock() {
		if (this.locked()) return;
		this._locked = true;
	}

	locked() {
		return Boolean(this._locked);
	}

	unlock() {
		this._locked = false;
	}

	tick = () => {
		if (this.locked()) return;
		const uris = Object.keys(this.announced);
		if (uris.length == 0) return;
		this.filter.uriSetFilter = new ods.devices.DeviceURISetFilter(uris);
		this.announced = {};
		this.store.dispatch(actions.find.request({ filter: this.filter }));
	}

	announce(announcements) {
		if (!this.started()) this.start();
		announcements.forEach(announcement => {
			if (
				cx.o.typeOf(announcement, ods.devices.DeviceNewMessageAnnouncement)
				|| cx.o.typeOf(announcement, ods.devices.DeviceNewEventAnnouncement)
			) {
				this.announced[announcement.uri] = true;
			}
		});
	}
}

export default Processor;
