import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useI18n } from '../../../../i18n';
import { actions as deviceFilterActions } from '../../../redux/app/deviceFilter';
import Input from '../../general/form/Input';

function DeviceSearchControl(props) {
	const { f } = useI18n();
	const inputRef = useRef(null);
	const [value, setValue] = useState(props.deviceFilter.name);

	const keydownHandler = (event) => {
		if (event.ctrlKey && event.keyCode == 70) {
			event.preventDefault();
			inputRef.current.focus();
		}
	}

	useEffect(() => {
		window.addEventListener('keydown', keydownHandler);
		return () => {
			window.removeEventListener('keydown', keydownHandler);
			onClear(); // maybe bad idea...
		}
	}, []);

	useEffect(() => {
		const timer = window.setTimeout(() => {
			props.dispatch(deviceFilterActions.search({ name: value }));
		}, 500);
		return () => window.clearTimeout(timer);
	}, [value]);

	const onClear = () => {
		props.dispatch(deviceFilterActions.search({ name: '' }));
	}

	let content = null;
	if (props.groupings.root != null) {
		content = (
			<div className="device-search-control">
				<Input
					placeholder={f('device filter')}
					value={value}
					onChange={setValue}
					inputRef={(element) => { inputRef.current = element; }}
					cleanable
				/>
			</div>
		)
	}

	return (content);
}

export default connect(
	state => ({
		groupings: state.categories.groupings,
		deviceFilter: state.deviceFilter
	})
)(DeviceSearchControl);
