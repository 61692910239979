import React from 'react';
import OlMap from 'ol/Map';
import { defaults as defaultControls } from 'ol/control';
import OlView from 'ol/View';
import OlGoogleMap from 'olgm/OLGoogleMaps';
import {
	boundingExtent,
	containsExtent,
	getCenter as extentCenter,
	getWidth as extentWidth,
	getHeight as extentHeight,
	containsCoordinate
} from 'ol/extent';
import { fromEPSG4326 } from 'ol/proj/epsg3857';
import { className, fromProps } from '../../../lib/className';
import { localStorage as storage } from '../../../app/storage';
import { cx } from '../../../api';
import Observable from '../../../misc/Observable';
import Loader from '../Loader';
import { layers, layersEnum } from './mapLayers';
import "./map.scss";

const DEFAULT_CENTER = [103.836, 1.3115];
const DEFAULT_ZOOM = 10;
const DEFAULT_DURATION = 0; // TODO doesn't focus with positive values since ol 6+
const MIN_ZOOM_PERCENT = 35;
const DEFAULT_STREET_VIEW_ZOOM_PERCENT = 70;
const DEFAULT_AUTO_ZOOM_PERCENT = 50;
const DEFAULT_PADDING = 30; // px
const DEFAULT_PADDINGS = [DEFAULT_PADDING, DEFAULT_PADDING, DEFAULT_PADDING, DEFAULT_PADDING];

const DEFAULT_MARGIN = 64; // px

const STORAGE_KEY = 'default-map';
const GOOGLE_MAP_TYPE_KEY = 'google-map-type';
const GOOGLE_MAP_TRAFFIC_KEY = 'google-map-traffic';
const DISPLAY_MARKER_LABELS = 'display-marker-labels';

export const FocusMode = {
	PlainFocus: 'plainFocus',
	DoubleFocus: 'doubleFocus'
};

class OwMap extends Observable {

	constructor(name) {
		super({ layerChanged: 'layerChanged' });
		this.map = new OlMap({
			target: null,
			controls: defaultControls({ attribution: false }),
			view: new OlView({
				center: fromEPSG4326(DEFAULT_CENTER),
				zoom: DEFAULT_ZOOM
			})
		});

		this.prevFocusOn = null;
		this.markerLabelsStorageKey = DISPLAY_MARKER_LABELS + name;
		this._displayMarkerLabels = !!storage.get(this.markerLabelsStorageKey);

		Object.values(layers).forEach(layerMeta => {
			this.addNameLayer(layerMeta.name, layerMeta.get());
		});
		this._handleLocation = this._handleLocation.bind(this);
		if ("geolocation" in navigator) {
			navigator.geolocation.getCurrentPosition(this._handleLocation);
		}
	}

	_handleLocation(position) {
		this.userLocation = position.coords;
		if (this.lastFocusDefault) {
			this.focus();
		}
	}

	getOlMap() {
		return this.map;
	}

	getDefaultStreetViewZoom() {
		return this.map.getView().getMaxZoom() / 100 * DEFAULT_STREET_VIEW_ZOOM_PERCENT;
	}

	activate(target) {
		this.map.setTarget(target);
		this.activateOlgm();
		this.setBaseLayerByName(storage.get(STORAGE_KEY) || layersEnum['GOOGLE']);
		cx.run.later(() => this.restoreGoogleMapOptions());
	}

	// google map

	activateOlgm() {
		this.olgm = new OlGoogleMap({ map: this.map });
		this.olgm.activate();
		this.olgm.getGoogleMapsMap().setTilt(0);
	}

	getOlgm() {
		return this.olgm;
	}

	getGoogleMap() {
		return this.getOlgm().getGoogleMapsMap();
	}

	getGoogleMapType() {
		const gmap = this.getGoogleMap();
		return gmap.mapTypeId;
	}

	setGoogleMapType(mapType) {
		const gmap = this.getGoogleMap();
		gmap.setMapTypeId(mapType);
		storage.set(GOOGLE_MAP_TYPE_KEY, mapType);
		this.applyLayerView(this.getBaseLayerName());
	}

	addGoogleTrafficLayer() {
		if (!this.gTrafficLayer) {
			this.gTrafficLayer = new window.google.maps.TrafficLayer();
			this.gTrafficLayer.setMap(this.getGoogleMap());
			storage.set(GOOGLE_MAP_TRAFFIC_KEY, true);
		}
	}

	removeGoogleTrafficLayer() {
		if (this.gTrafficLayer) {
			this.gTrafficLayer.setMap(null);
			this.gTrafficLayer = null;
			storage.remove(GOOGLE_MAP_TRAFFIC_KEY);
		}
	}

	hasGoogleTrafficLayer() {
		return !!this.gTrafficLayer;
	}

	restoreGoogleMapOptions() {
		const mapType = storage.get(GOOGLE_MAP_TYPE_KEY) || 'roadmap';
		this.setGoogleMapType(mapType);
		const withTraffic = !!storage.get(GOOGLE_MAP_TRAFFIC_KEY);
		if (withTraffic) this.addGoogleTrafficLayer();
	}

	// layers

	addNameLayer(name, layer) {
		if (!this.nameLayers) {
			this.nameLayers = {};
		}
		this.nameLayers[name] = layer;
		this.map.addLayer(layer);
	}

	layerName(layer) {
		for (var current in this.nameLayers) {
			if (this.nameLayers[current] == layer) return current;
		}
		return null;
	}

	setBaseLayerByName(name) {
		var layer = this.nameLayers ? this.nameLayers[name] : null;
		if (layer) {
			this.setBaseLayer(layer);
			storage.set(STORAGE_KEY, name);
		}
	}

	setBaseLayer(baseLayer) {
		this.applyLayerRules(baseLayer);
		const layers = Object.values(this.nameLayers);
		layers.forEach(function (layer) {
			layer.setVisible(layer == baseLayer);
			if (layer == baseLayer) {
				this.baseLayer = layer;
			}
		}, this);
		this.restoreGoogleMapOptions();
		this.notifyObservers(this.events.layerChanged, this);
	}

	getBaseLayer() {
		return this.baseLayer;
	}

	getBaseLayerName() {
		return this.layerName(this.baseLayer);
	}

	// layer rules

	applyLayerRules(layer) {
		const name = this.layerName(layer);
		this.applyLayerView(name);
		this.applyLayerInteractions(name);
		this.applyLayerControls(name);
	}

	applyLayerView(name) {
		const layerMeta = layers[name];
		const oldView = this.map.getView();
		const options = layerMeta.getViewOptions(this);
		const oldResolution = oldView.getResolution();
		if (oldResolution !== undefined) {
			if (options.maxResolution && oldResolution > options.maxResolution) {
				options.resolution = options.maxResolution;
			} else if (options.minResolution && oldResolution < options.minResolution) {
				options.resolution = options.minResolution;
			} else {
				options.resolution = oldResolution;
			}
		} else {
			options.zoom = DEFAULT_ZOOM;
		}
		const oldCenter = oldView.getCenter();
		options.center = oldCenter ? oldCenter : fromEPSG4326(DEFAULT_CENTER);
		this.map.setView(new OlView(options));
	}

	// controls

	hasControls() {
		return true; // TODO way to create clear map without controls
	}

	applyLayerControls(name) {
		this.clearControls();
		if (this.hasControls()) {
			const layerMeta = layers[name];
			layerMeta.controls().forEach(control => {
				this.map.addControl(control);
			});
		}
	}

	clearControls() {
		var controls = this.map.getControls().getArray().slice();
		controls.forEach(control => {
			this.map.removeControl(control);
		});
	}

	// marker labels

	displayMarkerLabels(display) {
		this._displayMarkerLabels = display;
		storage.set(this.markerLabelsStorageKey, this.isDisplayedMarkerLabels());
		const overlays = this.map.getOverlays();
		overlays.forEach(overlay => {
			const displayLabel = overlay.get('displayLabel');
			if (displayLabel) displayLabel();
		});
	}

	isDisplayedMarkerLabels() {
		return this._displayMarkerLabels;
	}

	// interactions

	applyLayerInteractions(name) {
		this.clearInteractions();
		const layerMeta = layers[name];
		layerMeta.interactions().forEach(interaction => {
			this.map.addInteraction(interaction);
		});
	}

	clearInteractions() {
		const interactions = this.map.getInteractions().getArray().slice();
		interactions.forEach(interaction => {
			this.map.removeInteraction(interaction);
		});
	}

	getAperture() {
		const size = this.map.getSize();
		return [size[0] - 2 * DEFAULT_PADDING, size[1] - 2 * DEFAULT_PADDING];
	}

	getApertureExtent() {
		return this.map.getView().calculateExtent(this.getAperture());
	}

	getBufferedExtent() {
		const size = this.map.getSize();
		return this.map.getView().calculateExtent([size[0] + 2 * DEFAULT_MARGIN, size[1] + 2 * DEFAULT_MARGIN]);
	}

	/**
	 * @param {module:ol/coordinate~Coordinate} center in EPSG:4326 format
	 * @param {number} zoom
	 * @param {boolean} instant do not animate
	 */

	focus(center, zoom, instant) {
		if (center == null && zoom == null) {
			if (this.userLocation != null) {
				center = fromEPSG4326([this.userLocation.longitude, this.userLocation.latitude]);
				zoom = DEFAULT_ZOOM;
			} else {
				this.lastFocusDefault = true;
				center = fromEPSG4326(DEFAULT_CENTER);
				zoom = DEFAULT_ZOOM;
			}
		} else {
			this.lastFocusDefault = false;
		}
		if (instant) {
			this.map.getView().setCenter(center);
			this.map.getView().setZoom(zoom);
		} else {
			this.map.getView().animate({ center, zoom, duration: DEFAULT_DURATION });
		}
	}

	/**
	 * @param {module:ol/extent~Extent} extent
	 * @param {Object} [options]
	 */

	fitExtent(extent, options) {
		options = options || {};
		if (!options.padding) options.padding = DEFAULT_PADDINGS;
		if (!options.size) options.size = this.getAperture();
		options.duration = DEFAULT_DURATION;
		this.map.getView().fit(extent, options);
	}

	/**
	 *
	 * Double focus logic:
	 * if extent is outside of current viewport - map moves to this extent;
	 * if extent is inside and previous focus was not on the given id - map moves to this extent;
	 * if extent is inside and previous focus was on this given id - map moves to this extent and set default zoom;
	 *
	 * Plain focus logic:
	 *
	 * @param {module:ol/extent~Extent} extent
	 * @param {string} [id]
	 * @param {boolean} [force] forces map to fit extent and set default zoom
	 * @param {FocusMode} [mode]
	 */

	focusExtent(extent, id, force, mode) {
		mode = mode ? mode : FocusMode.DoubleFocus;
		const aperture = this.getApertureExtent();
		let focusOn = false;
		if (mode == FocusMode.DoubleFocus) {
			focusOn = this.prevFocusOn == id && containsExtent(aperture, extent) ? true : false;
			this.prevFocusOn = id;
		}
		if (!force && !focusOn) {
			if (containsExtent(aperture, extent)) return;
			if (extentWidth(extent) <= extentWidth(aperture) && extentHeight(extent) <= extentHeight(aperture)) {
				this.focus(extentCenter(extent), this.map.getView().getZoom());
				return;
			}
		}
		if (extentWidth(extent) == 0 && extentHeight(extent) == 0) {
			this.focus(extentCenter(extent), this.getDefaultStreetViewZoom());

			return;
		}
		this.fitExtent(extent);
	}

	/**
	 * Focus map on markers with given ids or on all markers if no ids was passed
	 * @param {Array.<number>} ids
	 * @param {boolean} [force]
	 * @param {FocusMode} [mode]
	 */

	focusMarkers(ids, force, mode) {
		const overlays = this.map.getOverlays();
		if (overlays.getLength() == 0) return;
		if (ids && !Array.isArray(ids)) ids = [ids];
		const coordinates = [];
		if (ids) {
			let identity = '';
			ids.sort();
			ids.forEach((id) => {
				const marker = this.map.getOverlayById(id);
				if (marker) {
					const position = marker.getPosition();
					identity = identity.concat(id);
					if (position) coordinates.push(position);
				}
			}, this);
			if (coordinates.length > 0) {
				this.focusExtent(boundingExtent(coordinates), identity, force, mode);
			}
		} else {
			overlays.forEach(overlay => {
				const position = overlay.getPosition();
				if (position) coordinates.push(position)
			});
			this.focusExtent(boundingExtent(coordinates), null, false, mode);
		}
	}

	focusDomains(domains) {
		if (!Array.isArray(domains)) domains = [domains];
		const coordinates = this.map.getOverlays().getArray()
			.filter(overlay => {
				const domain = overlay.getProperties().domain;
				return domain && domains.indexOf(domain) >= 0;
			})
			.map(overlay => overlay.getPosition())
		;
		if (coordinates.length > 0) {
			const mapExtent = this.getApertureExtent();
			const view = this.map.getView();
			if (coordinates.length === 1) {
				const currentZoomPercent = view.getZoom() * 100 / view.getMaxZoom()
				const defaultAutoZoom = view.getMaxZoom() / 100 * DEFAULT_AUTO_ZOOM_PERCENT;
				if (currentZoomPercent < MIN_ZOOM_PERCENT) {
					view.animate({ center: coordinates[0], duration: 500 }, { zoom: defaultAutoZoom, duration: 500 });
				} else if (!containsCoordinate(mapExtent, coordinates[0])) {
					view.animate({ center: coordinates[0], duration: 1000 });
				}
			} else {
				const extent = boundingExtent(coordinates);
				const ratioWidth = extentWidth(mapExtent) / extentWidth(extent);
				const ratioHeight = extentHeight(mapExtent) / extentHeight(extent);
				if ((ratioWidth < 1 || ratioWidth > 20) || (ratioHeight < 1 || ratioHeight > 20)) {
					this.fitExtent(extent);
				} else if (!containsExtent(mapExtent, extent)) {
					view.animate({ center: extentCenter(extent), duration: 1000 });
				}
			}
		}
	}

	destroy() {
		this.map.setTarget(null);
		// this.map = null;
	}
}

// ---------------------------------------------------------

const maps = {};

class Map extends React.Component {

	constructor(props) {
		super(props);
		this.state = { height: 500 };
		this.map = new OwMap(this.props.name);
		this.mapRef = React.createRef();
		this.updateSize = this.updateSize.bind(this);
	}

	getOwMap() {
		return this.map;
	}

	getOlMap() {
		return this.map.getOlMap();
	}

	getDomBox() {
		return this.mapRef.current;
	}

	getDefaultStreetViewZoom() {
		return this.map.getDefaultStreetViewZoom();
	}

	updateSize() {
		if (this.mapRef.current) {
			let parent = this.mapRef.current.parentNode;
			this.mapRef.current.style.height = "100%";
			if (parent) this.setState({height: parent.clientHeight});
		}
		cx.run.later(() => this.map.getOlMap().updateSize());
	}

	render() {
		const children = React.Children.map(this.props.children, child =>
			React.cloneElement(child, { map: this.map })
		);
		return (
			<div
				ref={this.mapRef}
				style={{ width: "100%", height: this.state.height + "px" }}
				className={className('map', fromProps(this.props))}
			>
				{children}
				{this.props.pending &&
					<div className={'map-loader'}>
						<Loader />
					</div>
				}
				{this.props.controls}
			</div>
		);
	}

	componentDidMount() {
		maps[this.props.name] = this.map;
		this.map.activate(this.mapRef.current);
		window.addEventListener("resize", this.updateSize);
		this.updateSize();
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.updateSize);
		maps[this.props.name] = null;
		this.map.destroy();
	}
}

// ---------------------------------------------------------

Map.Layers = layersEnum;
const getMap = name => maps[name];

// ---------------------------------------------------------

export {
	Map,
	getMap as getOwMap,
};
