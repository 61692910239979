import React from 'react';
import { toEPSG4326 } from 'ol/proj/epsg3857';
import { MarkerOrderManagerContext } from './MarkerOrderManager';
import { EllipsoidalLocality } from '../../../misc/location/locality';

export const MarkerHoverManagerContext = React.createContext();

class MarkerHoverManager extends React.Component {
	constructor(props) {
		super(props);
		this.markers = new Map(); // Map<string, {id: string, coordinates: number[2], mapMarker: MapMarker}>
		this.hoveredMarker = null;
		this.mouseMove = this.mouseMove.bind(this);
		this.mouseLeave = this.mouseLeave.bind(this);
	}

	update(marker) {
		const existing = this.markers.get(marker.id);
		if (existing) {
			this.actualize(existing, marker);
			return;
		}
		const domBox = marker.mapMarker.getDomBox();
		domBox.addEventListener('mousemove', this.mouseMove);
		domBox.addEventListener('mouseleave', this.mouseLeave);
		this.markers.set(marker.id, marker);
	}

	actualize(marker, update) {
		const {coordinates, mapMarker} = update;
		Object.assign(marker, {coordinates, mapMarker});
	}

	mouseMove(event) {
		if (!this.props.map) return;
		const olMap = this.props.map.getOlMap();
		const pixel = olMap.getEventPixel(event);
		const withinVicinity = (() => {
			const radius = 32;
			const nw = toEPSG4326(olMap.getCoordinateFromPixel([pixel[0] - radius, pixel[1] + radius]));
			const se = toEPSG4326(olMap.getCoordinateFromPixel([pixel[0] + radius, pixel[1] - radius]));
			return coordinates => nw[0] < coordinates[0] && coordinates[0] < se[0]
				&& nw[1] < coordinates[1] && coordinates[1] < se[1]
		})();
		const mouseLocality = new EllipsoidalLocality(toEPSG4326(olMap.getCoordinateFromPixel(pixel)));
		let closestMarker = null, closestDistance2 = Infinity;
		for (const marker of this.markers.values()) if (withinVicinity(marker.coordinates)) {
			const distance2 = mouseLocality.distance2To(marker.coordinates);
			if (distance2 < closestDistance2) {
				closestMarker = marker;
				closestDistance2 = distance2;
			}
		}
		if (closestMarker) {
			if (this.hoveredMarker) {
				if (this.hoveredMarker.id == closestMarker.id) return;
				this.clearHighlight();
			}
			this.hoveredMarker = closestMarker;
			this.highlight();
		}
	}

	mouseLeave(event) {
		if (this.hoveredMarker && event.target == this.hoveredMarker.mapMarker.getDomBox()) {
			this.clearHighlight();
			this.hoveredMarker = null;
		}
	}

	highlight() {
		if (this.context) this.context.highlight(this.hoveredMarker.id);
		else this.hoveredMarker.mapMarker.setZIndex('100');
	}

	clearHighlight() {
		if (this.context) this.context.clearHighlight();
		else this.hoveredMarker.mapMarker.setZIndex('');
	}

	remove(id) {
		const marker = this.markers.get(id);
		if (!marker) return;
		const domBox = marker.mapMarker.getDomBox();
		domBox.removeEventListener('mousemove', this.mouseMove);
		domBox.removeEventListener('mouseleave', this.mouseLeave);
		this.markers.delete(id);
	}

	render() {
		return (
			<MarkerHoverManagerContext.Provider value={this}>
				{this.props.children}
			</MarkerHoverManagerContext.Provider>
		);
	}
}

MarkerHoverManager.contextType = MarkerOrderManagerContext;

export { MarkerHoverManager };
