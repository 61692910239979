import React, { useRef, useState, useEffect, useContext, useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { List } from 'rsuite';
import { useDrop } from 'react-dnd';

import { fc, useI18n } from '../../../../../i18n';
import { DragItemType } from '../../../share/dnd/DragItemType';
import DeviceList from '../devices/DeviceList';
import { FiPlus, FiMinus } from 'react-icons/fi';
import { cx } from '../../../../api';
import { actions as devicesActions } from '../../../../redux/api/devices';
import useLocalStorage from '../../../../misc/useLocalStorage';
import { getDevicesList, getDevicesCategoryList } from '../../../../redux/app/deviceFilter';
import { actions as contextActions } from '../../../../redux/app/context';
import uncategorizedIcon from '../../../../img/icons/uncategorized.png';
import { getOwMap } from '../../../general/location/Map';
import { ReduxKeyContext } from '../../../../misc/ReduxKeyContext';
import { className } from '../../../../lib/className';
import  {AllRoutes } from '../../../../../constants/routes';
import { SvgWrapper } from '../../../../../components/atoms/svg-wrapper';
import { hideGroup } from '../../../../../assets/icons';
import { Colors } from '../../../../../ui/constants/colors';

/**
 * @param {Object} props
 * @param {cx.ods.categories.CategoryData} [props.category] - null for uncategorized
 * @param {Array<number>} [props.hiddenCategories]
 * @param {number} [props.at]
 * @param {Array<ICategory>} [props.exceptCategories]
 * @param {function} props.handleCategoryVisibility
 * @param {React.RefObject} [props.firstMatchRef]
 */

function CategoryListItem(props) {
	const { f } = useI18n();

	const location = useLocation();

	const deviceListRef = useRef();
	const reduxKey = useContext(ReduxKeyContext);
	const pagesState = useSelector(state => state.pages[reduxKey]);

	const categoryId = props.category ? props.category.categoryId : null;

	const [filteredCollapsed, setFilteredCollapsed] = useState(false);
	const [baseCollapsed, setBaseCollapsed] = useLocalStorage(`category-list-item-${categoryId}`);
	const [forceExpanded, setForceExpanded] = useState(false);

	const collapsed = (!props.deviceFilter.name ? !(baseCollapsed == false) : filteredCollapsed) && !forceExpanded;
	const setCollapsed = collapsed => {
		(!props.deviceFilter.name ? setBaseCollapsed : setFilteredCollapsed)(collapsed);
		if (forceExpanded) setForceExpanded(false);
	};

	useEffect(() => {
		setFilteredCollapsed(false);
	}, [props.deviceFilter]);

	// ------------- drop device -------------------

	const adoptDevice = (item, targetId) => {
		const categoryIds = item.device.categoryIds;
		if (item.categoryId === targetId) return;
		const deviceInfo = item.device.createInfo();
		if (item.categoryId) deviceInfo.categoryIds.splice(categoryIds.indexOf(item.categoryId), 1);
		if (targetId) deviceInfo.categoryIds.push(targetId);
		props.dispatch(devicesActions.update.request({ uri: item.device.uri, deviceInfo }));
	}

	const [stageDropDevice, dropDevice] = useDrop({
		accept: DragItemType.DEVICE,
		drop: item => adoptDevice(item, categoryId),
		collect: monitor => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop()
		}),
	});

	const isActiveDevice = stageDropDevice.isOver && stageDropDevice.canDrop;

	// --------------- drop actions ------------------

	const adoptActions = (item, targetCategory) => {
		switch (item.type) {
			case DragItemType.ACTION_EDIT:
				props.dispatch(contextActions.actionSet({
					actionType: 'edit',
					name: 'category',
					data: {
						title: "group",
						offsetTop: cx.dom.at.client(document.getElementById('category-' + categoryId)).top,
						id: targetCategory.categoryId,
						elementHeight: document.getElementById('category-' + categoryId).clientHeight
					}
				}));
				break;
			case DragItemType.ACTION_REMOVE:
				props.dispatch(contextActions.actionSet({
					actionType: 'remove',
					name: 'category',
					data: {
						title: "group",
						offsetTop: cx.dom.at.client(document.getElementById('category-' + categoryId)).top,
						id: targetCategory.categoryId,
						elementHeight: document.getElementById('category-' + categoryId).clientHeight
					}
				}));
				break;
		}
	};

	const [stageDropActions, dropAction] = useDrop({
		accept: [DragItemType.ACTION_EDIT, DragItemType.ACTION_REMOVE],
		drop: item => adoptActions(item, props.category),
		collect: monitor => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop()
		}),
	});

	const isActiveAction = stageDropActions.isOver && stageDropActions.canDrop;

	// ----------------- device list ------------------

	function getDevices() {
		return categoryId
			? getDevicesCategoryList(categoryId)
			: getDevicesList().filter(device => {
				const categoryIds = device.categoryIds, excepts = props.exceptCategories;
				if (!categoryIds || !excepts) return true;
				return categoryIds.every(categoryId => !excepts.some(except => except.categoryId == categoryId));
			})
		;
	}
	const devices = props.devices.categoriesMap != null ? getDevices() : [];

	const deviceList = 0 < devices.length && !collapsed
		? (<DeviceList
			devices={devices}
			customRef={deviceListRef}
			categoryId={categoryId}
		/>)
		: null
	;

	// ---------------- expand / collapse ----------------

	const getSelectedURI = () => {
		const selectedURI = pagesState.lastSelected?.uri;
		if (!selectedURI || !pagesState.selectionMap[selectedURI]) return null;
		return devices.some(device => device.uri == selectedURI) ? selectedURI : null;
	};
	const selectedURI = getSelectedURI();

	// tricky way to focus device list item only after device list already expanded (if it's collapsed)
	// useLayoutEffects fire before any useEffect but in same order (child -> parent)
	const focusURIRef = useRef(null);
	useLayoutEffect(() => {
		if (selectedURI == null) setForceExpanded(false);
		else {
			focusURIRef.current = selectedURI;
			if (collapsed) setForceExpanded(true);
		}
	}, [selectedURI == null]);

	useEffect(() => {
		if (focusURIRef.current && deviceListRef.current != null) {
			deviceListRef.current.focus(focusURIRef.current);
			focusURIRef.current = null;
		}
	}, [focusURIRef.current]);

	// ---------------- filter focus ----------------
	useEffect(() => {
		if (props.deviceFilter.name && 0 < devices.length) {
			if (props.firstMatchRef.current === undefined) {
				if (deviceListRef.current) deviceListRef.current.focus(devices[0].uri);
				props.firstMatchRef.current = categoryId;
			}
		}
	}, [props.deviceFilter]);

	const focus = () => {
		const markersId = devices.map(device => device.uri);
		getOwMap(reduxKey).focusMarkers(markersId);
	}

	// ---------------------------------------------------
	const toggleView = () => {
		setCollapsed(!collapsed)
	}

	const toggleIcon = collapsed ? (<FiPlus size={16} />) : (<FiMinus size={16} />);

	const isCategoryHidden = props.hiddenCategories?.includes(categoryId || 0);

	return (
		<div ref={dropDevice}>
			<div ref={categoryId ? dropAction : null} id={'category-' + categoryId}>
				<List.Item className={className('category', { 'droppable': (isActiveDevice || isActiveAction) })}>
					<div className="content">
						<span className={className('category-icon', { 'dynamic': categoryId })} onClick={focus}>
							{props.at ? props.at : <img src={uncategorizedIcon} alt="" />}
							{categoryId && (<span className="badge"><span>{devices.length}</span></span>)}
						</span>
						{!props.sidebarCollapsed && <>
							<span className="category-name"
								  onClick={focus}>{props.category ? props.category.name : f('uncategorized')}</span>
							{location.pathname.includes(AllRoutes.Dashboard) &&
								<span
									title={fc(isCategoryHidden ? 'show markers' : 'hide markers')}
									className="markers-visibility"
									style={{...(isCategoryHidden && { color: Colors.ScubaBlue })}}
									onClick={props.handleCategoryVisibility(categoryId || 0)}
								>
									<SvgWrapper width={16} height={16} details={hideGroup} />
								</span>}
							<span title={fc(collapsed ? 'expand' : 'collapse')} className="toggle-view" onClick={toggleView}>
								{toggleIcon}
							</span>
						</>}
					</div>
				</List.Item>
			</div>
			{!props.sidebarCollapsed &&
				<List.Item className={className('sub-list', {'collapsed': collapsed})}>
					{deviceList}
				</List.Item>
			}
		</div>
	);
}

export default connect(
	state => ({
		devices: state.devices,
		deviceFilter: state.deviceFilter,
		sidebarCollapsed: state.context.sidebarCollapsed
	})
)(CategoryListItem);
