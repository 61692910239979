import React from 'react';

const orderOptionMap = {
	time: {
		key: 'time',
		description: 'update time'
	},
	latitude: {
		key: 'latitude',
		description: 'latitude'
	}
}

export const MarkerOrderManagerContext = React.createContext();

const zIndexIncrement = 10;

// marker: {id: string, selected: boolean, coordinates: number[2], time: Date, mapMarker: MapMarker}
class MarkerOrderManager extends React.Component {

	constructor(props) {
		super(props);
		this.pendingUpdates = [];
		this.markers = [];
		this.markerIndex = new Map();
		window.mom = this;
	}

	update(marker) {
		this.pendingUpdates.push(marker);
		if (this.updateTimer != null) return;
		this.updateTimer = window.setTimeout(() => {
			this.updateTimer = null;
			this.applyUpdates(this.pendingUpdates);
			this.pendingUpdates = [];
		});
	}

	highlight(id) {
		const updates = [];
		if (this.highlightedId) {
			const highlighted = this.markerIndex.get(this.highlightedId);
			if (highlighted) updates.push(highlighted);
		}
		const marker = this.markerIndex.get(id);
		if (marker) updates.push(marker);
		this.highlightedId = id;
		if (0 < updates.length) this.applyUpdates(updates);
	}

	clearHighlight() {
		if (this.highlightedId == null) return;
		const marker = this.markerIndex.get(this.highlightedId);
		this.highlightedId = null;
		if (marker) this.applyUpdates([marker]);
	}

	applyUpdates(updates) {
		let needsReindex = false;
		updates.forEach(update => {
			let position = this.findPosition(update), markerAt = null;
			if (position < this.markers.length) {
				markerAt = this.markers[position];
				if (markerAt.id == update.id) {
					this.actualize(markerAt, update);
					return;
				}
			}
			let marker = this.markerIndex.get(update.id);
			if (marker == null) {
				marker = update;
				this.markerIndex.set(update.id, marker);
			} else {
				const removeAt = this.findIndex(marker);
				this.markers.splice(removeAt, 1);
				if (removeAt <= position) --position;
				this.actualize(marker, update);
			}
			this.markers.splice(position, 0, marker);
			marker.lastIndex = position;
			if (needsReindex) return;
			const lowerZIndex = 0 < position ? this.markers[position - 1].zIndex : 0;
			let zIndex;
			if (markerAt == null) zIndex =  lowerZIndex + zIndexIncrement;
			else {
				const gap = markerAt.zIndex - lowerZIndex;
				if (gap < 2) {
					needsReindex = true;
					return;
				}
				zIndex = lowerZIndex + (gap >> 1);
			}
			update.zIndex = zIndex;
			update.mapMarker.setZIndex(zIndex);
		});
		if (needsReindex) this.reindex();
	}

	findPosition(marker) {
		const comparator = this.comparator();
		let start = 0, end = this.markers.length;
		while (start < end) {
			const at = (start + end) >> 1;
			const compared = comparator(marker, this.markers[at]);
			if (compared < 0) end = at;
			else if (0 < compared) start = at + 1;
			else return at;
		}
		return start;
	}

	findIndex(marker) {
		const id = marker.id, lastIndex = marker.lastIndex;
		if (this.markers.length <= lastIndex) {
			for (let at = this.markers.length - 1; 0 <= at; --at) {
				if (this.markers[at].id == id) return at;
			}
			throw new Error('Invariant violated');
		}
		if (this.markers[lastIndex].id == id) return lastIndex;
		let backwardAt = lastIndex - 1, forwardAt = lastIndex + 1;
		for (; 0 <= backwardAt && forwardAt < this.markers.length; --backwardAt, ++forwardAt) {
			if (this.markers[backwardAt].id == id) return backwardAt;
			if (this.markers[forwardAt].id == id) return forwardAt;
		}
		for (; 0 <= backwardAt; --backwardAt) {
			if (this.markers[backwardAt].id == id) return backwardAt;
		}
		for (; forwardAt < this.markers.length; ++forwardAt) {
			if (this.markers[forwardAt].id == id) return forwardAt;
		}
		throw new Error('Invariant violated');
	}

	actualize(marker, update) {
		const {selected, coordinates, time, mapMarker} = update;
		Object.assign(marker, {selected, coordinates, time, mapMarker});
	}

	rebuild() {
		this.markers.sort(this.comparator());
		this.reindex();
	}

	comparator() {
		const compare = (left, right, optionCompare) => {
			let compared = (left.id == this.highlightedId ? 1 : 0) - (right.id == this.highlightedId ? 1 : 0);
			if (compared != 0) return compared;
			compared = (left.selected ? 1 : 0) - (right.selected ? 1 : 0);
			if (compared != 0) return compared;
			compared = optionCompare(left, right);
			if (compared != 0) return compared;
			if (left.id < right.id) return -1;
			if (right.id < left.id) return 1;
			return 0;
		};
		switch (this.props.orderOption) {
			case orderOptionMap.latitude.key: return (left, right) => compare(left, right, (left, right) => -(left.coordinates[1] - right.coordinates[1]));
			case orderOptionMap.time.key:
			default:
				return (left, right) => compare(left, right, (left, right) => left.time.getTime() - right.time.getTime());
		}
	}

	reindex() {
		this.markers.forEach((marker, at) => {
			const zIndex = (at + 1) * zIndexIncrement;
			marker.zIndex = zIndex;
			marker.mapMarker.setZIndex(zIndex);
		});
	}

	remove(id) {
		if (this.highlightedId == id) this.highlightedId = null;
		const marker = this.markerIndex.get(id);
		if (marker == null) return;
		this.markers.splice(this.findIndex(marker), 1);
		this.markerIndex.delete(id);
	}

	render() {
		return (
			<MarkerOrderManagerContext.Provider value={this}>
				{this.props.children}
			</MarkerOrderManagerContext.Provider>
		);
	}

	componentDidUpdate(prevProps) {
		if (this.props.orderOption != prevProps.orderOption) this.rebuild();
	}
}

MarkerOrderManager.orderOptionMap = orderOptionMap;
export { MarkerOrderManager };
