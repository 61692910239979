import React, { useMemo } from 'react';
import { useDragLayer } from 'react-dnd';
import { DragItemType } from './DragItemType';
import editIcon from '../../../img/icons/edit.png';
import pinIcon from '../../../img/icons/pin-empty.png';
import removeIcon from '../../../img/icons/trash.png';
import DeviceListItemContent from '../../custom/sidebar/devices/DeviceListItemContent';
import './dragLayer.scss';

const style = {
	position: 'fixed',
	pointerEvents: 'none',
	zIndex: 1100,
	width: '100%',
	height: '100%'
}

function getItemStyle(currentOffset, item) {
	if (!currentOffset) return { display: 'none' };
	const { x, y } = currentOffset, left = x + 'px', top = y + 'px';
	if (!item?.width) return { left, top };
	return { left, top, width: item.width + 'px' };
}

// ---------------------------------------------------------------

function DragLayer() {
	const {
		isDragging, item, itemType, offset
	} = useDragLayer(monitor => ({
		isDragging: monitor.isDragging(),
		item: monitor.getItem(),
		itemType: monitor.getItemType(),
		offset: monitor.getSourceClientOffset()
	}));

	const itemImage = useMemo(() => {
		switch (itemType) {
			case DragItemType.ACTION_EDIT:
				return (
					<div className="action-item">
						<img src={editIcon} alt="" title="Edit" />
					</div>
				);
			case DragItemType.ACTION_PIN:
				return (
					<div className="action-item">
						<img src={pinIcon} alt="" title="Pin" />
					</div>
				);
			case DragItemType.ACTION_REMOVE:
				return (
					<div className="action-item">
						<img src={removeIcon} alt="" title="Delete" />
					</div>
				);
			case DragItemType.DEVICE:
				return <DeviceListItemContent
					pinned={item && item.pinned}
					device={item && item.device}
					reduxKey={item && item.reduxKey}
				/>;
			default:
				return null
		}
	}, [itemType, item]);

	if (!isDragging || itemImage == null) return null;

	return <div style={style} className="drag-layer">
		<div className="item" style={getItemStyle(offset, item)}>
			{itemImage}
		</div>
	</div>;
}

export default DragLayer;
