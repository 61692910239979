import React, { Dispatch, FC, useContext, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { List } from 'rsuite';

import { ICategory } from 'types/categories';
import { IReduxState } from 'types/redux';
import Loader from 'core/react/general/Loader';
import { fetchChildren as fetchGroupingCategories } from 'core/redux/app/categories/grouping';
import { categoriesGroupingsSelector } from 'core/redux/selectors/categories';
import { sortByName } from 'core/lib/sorting';
import { actions } from 'core/redux/app/categories/visibility';
import { GroupingIdContext } from '../device-side-menu';
import CategoryListItem from './CategoryListItem';

import './category-list.scss';

type CategoryVisibilityActions = {
  setCategoriesVisibility: (spec: {
    currentCategoriesIds: number[];
    hiddenCategoriesIds: number[];
    isDeviceFocusDisabled: boolean;
  }) => {};
};

interface ICategoryListProps {
  groupingId?: number;
  hiddenCategories: number[];
  setHiddenCategories: Dispatch<number[]>;
}

export const CategoryList: FC<ICategoryListProps> = ({ groupingId, hiddenCategories, setHiddenCategories }) => {
  const storedGroupingId = useContext(GroupingIdContext);
  const dispatch = useDispatch();

  const { root: rootCategory, map: groupingsMap } = useSelector(categoriesGroupingsSelector);

  const groupingIdRoot = storedGroupingId || groupingId;

  const categories = useMemo(() => {
    const categories: ICategory[] | null =
      (!!rootCategory && !!groupingIdRoot && groupingsMap?.[groupingIdRoot]) || null;

    return categories?.length ? (sortByName(categories) as ICategory[]) : categories;
  }, [rootCategory, groupingIdRoot, groupingsMap]);

  const setCategoriesVisibility = () => {
    if (categories) {
      const currentCategoriesIds = categories.map((category) => category.categoryId);
      const categoriesVisibility = {
        currentCategoriesIds,
        hiddenCategoriesIds: hiddenCategories,
        isDeviceFocusDisabled: false,
      };

      dispatch((actions as CategoryVisibilityActions).setCategoriesVisibility(categoriesVisibility));
    }
  };

  const onCategoriesVisibilityChange = (categoryId: number) => () => {
    if (categories) {
      const currentCategoriesIds = categories.map((category) => category.categoryId);
      const isHiddenCategory = hiddenCategories.includes(categoryId);

      const hiddenCategoriesIds = isHiddenCategory
        ? hiddenCategories.filter((id: number) => id !== categoryId)
        : [...(hiddenCategories || []), categoryId];

      setHiddenCategories(hiddenCategoriesIds);
      dispatch(
        (actions as CategoryVisibilityActions).setCategoriesVisibility({
          currentCategoriesIds,
          hiddenCategoriesIds,
          isDeviceFocusDisabled: isHiddenCategory,
        }),
      );
    }
  };

  useEffect(() => {
    if (rootCategory && groupingIdRoot && !categories) {
      fetchGroupingCategories(groupingIdRoot);
    }
  }, [groupingsMap, groupingIdRoot]);

  useEffect(() => {
    setCategoriesVisibility();
  }, [categories]);

  const deviceFilter = useSelector((state: IReduxState) => state.deviceFilter);
  const firstMatchRef = useRef(undefined);
  useEffect(() => {
    firstMatchRef.current = undefined;
  }, [deviceFilter]);

  return rootCategory && categories ? (
    <List bordered={false} className="scrollbar dark category-list ">
      {categories?.map((category, at) => (
        <CategoryListItem
          key={category.categoryId}
          category={category}
          at={at + 1}
          hiddenCategories={hiddenCategories}
          handleCategoryVisibility={onCategoriesVisibilityChange}
          firstMatchRef={firstMatchRef}
        />
      ))}
      <CategoryListItem
        key={'uncategorized'}
        exceptCategories={categories}
        category={null}
        hiddenCategories={hiddenCategories}
        handleCategoryVisibility={onCategoriesVisibilityChange}
        firstMatchRef={firstMatchRef}
      />
    </List>
  ) : (
    <div className="loading-wrapper">
      <Loader size={Loader.Size.LG} />
    </div>
  );
};
